<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(tip,tipIndex) in banner.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                  </div>
                </v-card-title>
                <v-card-actions class="pa-0 mt-6 justify-center" v-if="banner.btn">
                  <v-btn
                      rounded
                      color="#0568FD"
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click="dialog=true"
                  >
                    <span class="color-FFF">{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>

    <v-dialog 
      v-model="dialog"
      width='550'
    >
      <v-card color="transparent" flat class="transparent_card position-r">
        <v-card-title class="close-btn" @click.stop="dialog = false">x</v-card-title>
        <v-card-title class="font-size-24 justify-center " >立即咨询 </v-card-title>
        <div class="box">
          <div class="box-ewm">
            <v-img src='https://h5.ophyer.cn/official_website/other/ar-ip.png' width="200" height="200" style="margin: 0 auto;" contin></v-img>
            <v-card-text class="text-align-center font-size-14 pa-0 color-666" style="margin-top: -3px">扫一扫加我微信</v-card-text>
          </div>
          <div class="box-row">
            <v-row class="pa-0 mt-8">
              <div class="d-inline width-60 ml-7" style="margin-top: -10px;">
                <v-avatar size="50" rounded-2>
                  <v-img contain src="https://h5.ophyer.cn/official_website/other/ar-diloag-icon1.png"></v-img>
                </v-avatar>
              </div>
              
              <div class="d-inline ml-3 mt-n2">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-18">
                    赵总
                  </v-card-title>
                  <v-card-text class="pa-0 font-size-14 color-999">18813090558</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-5">
              <div class="d-inline width-60 ml-7">
                <v-avatar size="50" rounded-2>
                  <v-img contain src="https://h5.ophyer.cn/official_website/other/ar-diloag-icon2.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-18">
                    邮箱
                  </v-card-title>
                  <v-card-text class="pa-0  font-size-14 color-999">zhaoxiaozhi9@163.com</v-card-text>
                </v-card>
              </div>
            </v-row>
            <v-row class="pa-0 mt-5">
              <div class="d-inline width-60 ml-7">
                <v-avatar size="50" rounded-2>
                  <v-img contain src="https://h5.ophyer.cn/official_website/other/ar-diloag-icon3.png"></v-img>
                </v-avatar>
              </div>
              <div class="d-inline ml-3 mb-8">
                <v-card color="transparent" flat>
                  <v-card-title class="pa-0 font-size-18">
                    地址
                  </v-card-title>
                  <v-card-text class="pa-0  font-size-14">北京市朝阳区竞园商八</v-card-text>
                </v-card>
              </div>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- 应用场景 -->
    <div class="d-flex justify-center width-per-100 warps warp1" style="padding-bottom: 20px;">
      <v-card flat class="max-width-1200 width-per-100" color="transparent">
        <div class="public-title">
          <p>CORE GAMEPLAY</p>
          <div>
            <span>核心玩法</span>
            <span>任意配置打卡点组成不同游览路线，通过游戏互动的形式吸引游客更充分的游览景区</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered  color="#0084ff" class="g-tabs">
          <v-tab v-for="(v, k) in data.tabList" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(v, k) in data.tabList" :key="k" :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <div class="left" v-show="tab == k"><img :src='v.img' /></div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <div class="right" v-show="tab == k">
                    <v-card-text class="title" v-text="v.lunTitle" />
                    <v-card-text class="tip" v-text="v.tip" />
                    <v-card-text class="text" v-for="(i, j) in v.text" :key="j" v-text="i" />
                  </div>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>

    <!-- 产品插件 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>PRODUCT PLUG-IN</p>
        <div>
          <span>产品插件</span>
          <span>专注AR技术，专注服务景区数字化升级转型</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="4" v-for="n in data.warp2" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- 产品插件 -->
    <v-card class="warps warp3" :flat="true">
      <div class="public-title">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>产品优势</span>
          <span>深耕行业数十年，提供安全、稳定的技术服务</span>
        </div>
      </div>
      <v-container>
        <v-row class="max-width-1200 " style="margin:0 auto" >
          <v-col cols="3" v-for="n in data.warp3" :key="n.id" >
            <v-card flat>
              <div class="img_title" justify="center">
                <img :src="n.src" />
                <h2>{{n.tit}}</h2>
                <p v-html="n.content">
                  {{n.content}}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>


     <!-- 增值服务 -->
    <v-card class="warps warp4" :flat="true">
      <div class="public-title">
        <p>VALUE ADDED SERVICES</p>
        <div>
          <span>增值服务</span>
          <span>深耕行业数十年，提供安全、稳定的技术服务  </span>
        </div>
      </div>
      <div class="con">
        <v-row>
          <v-col
            v-for="n in data.warp4"
            :key="n.id"
            class="d-flex child-flex"
            cols="3"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div>
                {{ n.tit }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

  </v-app>
</template>

<script>
import textData from './textData.js';
import qs from 'qs'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data: textData.Home,
      tab: null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/arPunch-solution-banner.png",
          title: ["AR互动打卡游戏"],
          titleColor:"#FFFFFF",
          tip: ["针对景区、活动展览制作的线上/线下一体化小程序互动游戏"],
          btn: '立即咨询'
        },
      ],

      showDialog: false,
      dialog: false
    }
  },
  created() {
  },
  methods: {
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog{
  background: linear-gradient(180deg, #FFFFFF, #fff);
  border: 2px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0px 10px 20px 0px rgba(68, 148, 255, 0.1);
  border-radius: 10px;
  .close-btn {
    position: absolute;
    right: -12px;
    top: -10px;
    cursor: pointer;
  }
  .box {
    margin: 25px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-ewm {
    text-align: center;
  }
}
::v-deep .transparent_card{
  .v-card__text{
    line-height: 26px !important;
  }
  .v-card__title{
    line-height: 26px !important;
  }
}
::v-deep .v-card__subtitle, .text-subtitle-1{
  line-height: 20px !important;
  padding:1px 6px 6px 0;
}


.warps {
  margin: 0 auto;
  text-align: center;
  width: 1200px !important;
}

.warp1 {
  .public-title {
    margin-top: 40px;
  }
  .g-tabs {
    margin-top: 20px;
  }
  .box {
    width: 1000px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 500px;
      height: 300px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
      color: #666;
    }
  }
}

.warp2 {
  .public-title {
    margin-bottom: 25px;
  }
  .img_title {
    width: 266px;
    margin: 0 auto;
    text-align: center;
    img {
      margin: 0 auto;
      width: 200px ;
      height: 320px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 10px;
      line-height: 20px;
    }
  }
}

.warp3 {
  .public-title {
    margin-bottom: 15px;
  }
  .img_title {
    width: 266px;
    margin: 15px auto;
    text-align: center;
    img {
      margin: 0 auto;
      width: 120px;
      height: 120px;
    }
    h2 {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #666;
      margin-top: 10px;
      line-height: 20px;
    }
  }
}

.warp4 {
  margin-top: -15px;
  margin-bottom: 60px;
  .con {
    margin-top: 35px;
    .box-img {
      width: 240px;
      height: 160px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

</style>
